import {OnDestroy, OnInit, signal, WritableSignal} from '@angular/core';
import {Component} from '@angular/core';
import {LayoutService} from './service/app.layout.service';
import {DataService} from "../service/data.service";
import {BetaReaderProfile, PlatformUser, WriterProfile} from "../../API";
import {PlatformRole} from "../api/enums";
import {Observable, Subscription} from "rxjs";
import {ErrorService, LogProvider} from "../service/error.service";
import {NavigationEnd, Router} from "@angular/router";
import {DocumentService, SectionLink} from "../service/document.service";
import {ProjectSchema} from "../api/project_schemas";
import {toObservable} from "@angular/core/rxjs-interop";
import { TreeModule } from 'primeng/tree';
import {TreeNode} from "primeng/api";
import {TableOfContents, Section} from "../components/contents-management/TableOfContentsBlot";

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit, OnDestroy {

    private readonly LOG = LogProvider.getLogger('AppMenuComponent');

    protected model: any[] = [];
    roles: any[] = [];
    betaReaderProjects: any[] =[];
    writerProjects: any[] = [];
    platformUser: PlatformUser;
    betaReader: BetaReaderProfile;
    writer: WriterProfile;
    private subscriptions: Subscription[] = []

    protected isEditMode = signal(false);
    private isEditModeObs: Observable<boolean> = toObservable(this.isEditMode);
    protected treeModel: TreeNode[];

    constructor(public route: Router, public layoutService: LayoutService, private dataService: DataService, protected documentService: DocumentService, private errorService: ErrorService) {
        // this.LOG.debug("app.menu.component: dataService: ", dataService);
        // this.LOG.debug("app.menu.component: errorService: ", errorService);
        this.route.events.subscribe(e => {
            //this.LOG.debug('Event:', e);
            if (e instanceof NavigationEnd) {
                const ru = this.route.url.toLowerCase();
                const editorActive = ru.startsWith('/content');
                this.isEditMode.set(editorActive);
                this.LOG.debug('Updating editMode signal for MENU:', ru,  editorActive, this.isEditMode());
            }
        });
    }

    async ngOnInit() {
        this.subscriptions.push(this.dataService.betaReader.subscribe(u => {
            this.betaReader = u;
        }));
        this.subscriptions.push(this.dataService.writer.subscribe(u => {
            this.writer = u;
        }));
        this.subscriptions.push(this.dataService.betaReaderProjects.subscribe(u => {
            this.betaReaderProjects = [];
            for (let proj of u) {
                this.betaReaderProjects.push({
                    label: proj.project.title,
                    icon: 'pi pi-fw pi-book',
                    routerLink: ['/', 'beta_reader', proj.betaReaderProject.uuid]
                });
            }
            this.betaReaderProjects.push({
                label: 'Find Projects',
                icon: 'pi pi-fw pi-plus',
                routerLink: ['/', 'beta_reader', 'new']
            });
        }));
        this.subscriptions.push(this.dataService.writerProjects.subscribe(u => {
            this.writerProjects = [];
            if (u) {
                for (let proj of u) {
                    this.writerProjects.push({
                        label: proj.title,
                        icon: 'pi pi-fw pi-book',
                        routerLink: ['/', 'writer', proj.uuid]
                    });
                }
            }
            //Add a "Create New Project" button
            this.writerProjects.push({
                label: 'New Project',
                icon: 'pi pi-fw pi-plus',
                routerLink: ['/', 'writer', 'new']
            });

        }));

        this.platformUser = this.dataService.user();
        this.roles.length = 0;
        if (this.dataService.isRnGAdmin()) {
            this.roles.push({label: 'R&G Admin', icon: 'pi pi-fw pi-cog', routerLink: ['/admin']});
        }
        if (this.platformUser.roles.includes(PlatformRole.WRITER.valueOf())) {
            let items = {
                label: 'Writer',
                items: this.writerProjects,
                expanded: true,
                icon: 'pi pi-fw pi-pencil',
                routerLink: ['/', 'writer']
            };
            this.roles.push(items);
        }
        if (this.platformUser.roles.includes(PlatformRole.BETAREADER.valueOf())) {
            let items = {
                label: 'Beta Reader',
                items: this.betaReaderProjects,
                expanded: true,
                icon: 'pi pi-fw pi-book',
                routerLink: ['/', 'beta_reader']
            };
            this.roles.push(items);
        }
        this.buildMenuModel();

        this.subscriptions.push(this.isEditModeObs.subscribe(() => {
            this.LOG.debug('isEditMode Signal');
            this.buildMenuModel();
        }));
        this.subscriptions.push(this.documentService.tableOfContentsObs.subscribe(e => {
            this.LOG.debug('toc Signal', e);
            this.buildMenuModel(e);
        }));

        //JIRA - Collector
        let node = document.createElement('script');
        node.src = "https://runeandgear.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-50n9c3/b/8/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=ef97caeb";
        node.type = 'text/javascript';
        document.getElementsByTagName('head')[0].appendChild(node);

        node = document.createElement('script');
        node.type = 'text/javascript';
        node.innerText = 'window.ATL_JQ_PAGE_PROPS =  { "triggerFunction": function(showCollectorDialog) { jQuery(".REPORT_ISSUE_TRIGGER")[0].onclick = function(e) { e.preventDefault(); showCollectorDialog();};}};';
        document.getElementsByTagName('head')[0].appendChild(node);
        // this.LOG.debug('NOT REDIRECTING HERE');
        // this.dataService.redirectToHome();
    }

    private buildTreeModelFromTOC(link: SectionLink, schema: ProjectSchema): TreeNode {
        const schemaEntry = schema.sectionLayout[link.section.level];
        let ret: TreeNode = {
            label: link.section.name,
            icon: schemaEntry.tocIconHTML,
            data: link.section,
            type: 'section',
            expanded: true,
            children: []
        };
        for ( var child of link.childLinks ) {
            ret.children.push(this.buildTreeModelFromTOC(child, schema));
        }
        return ret;
    }

    protected editSectionMetadata(link: SectionLink) {
        this.LOG.debug('EditSectionMetadata', link);
    }

    private buildMenuModel(toc: TableOfContents = null) {
        const newModel = [];

        this.treeModel = null;

        if (this.isEditMode() && toc) {
            this.treeModel = [this.buildTreeModelFromTOC(toc.topLevelSectionLink, toc.projectSchema)];
            this.LOG.debug('TreeModel: ', this.treeModel);
        }

        if (!this.isEditMode()) {
            newModel.push(
                {
                    label: 'I want to work on a project as a',
                    items: this.roles
                });
        }

        newModel.push(
            {
                label: 'Resources',
                items: [
                    {
                        label: 'Report an Issue',
                        icon: 'pi pi-fw pi-wrench',
                        class: 'REPORT_ISSUE_TRIGGER',
                        target: '_blank'
                    },
                    {
                        label: 'See What\'s Coming',
                        icon: 'pi pi-fw pi-map',
                        url: ['https://docs.google.com/forms/d/e/1FAIpQLSey5XTPEYB4vwcdijPTSqQFU7Pgvyt0X3Gr_yP0EBiXoK0ZiQ/viewform?usp=sf_link'],
                        target: '_blank'
                    },
                    {
                        label: 'Scrivendium Beta Community',
                        icon: 'pi pi-fw pi-discord',
                        url: ['https://discord.gg/TgNX83d5Xx'],
                        target: '_blank'
                    },
                    {
                        label: 'Scrivendium Announcements',
                        icon: 'pi pi-fw pi-compass',
                        url: ['https://runeandgear.com/scrivendium-announcements-388/'],
                        target: '_blank'
                    },
                    {
                        label: 'Rune and Gear Writer\'s  Community',
                        icon: 'pi pi-fw pi-discord',
                        url: ['https://coming_soon'],
                        target: '_blank'
                    }
                ]
            });
        if (this.dataService.isDeveloper()) {
            newModel.push(
                {
                    label: 'Reference',
                    items: [
                        {
                            label: 'Dump Debug to Console',
                            icon: 'pi pi-fw pi-eye',
                            command: e => this.errorService.gatherDebug()
                        },
                        {
                            label: 'Dump Hydration',
                            icon: 'pi pi-fw pi-eye',
                            url: ['http://10.0.90.90:4200/#/admin?session=' + this.dataService.getHydrationUUID()]
                        },
                        {
                            label: 'UI Components',
                            items: [
                                {
                                    label: 'Form Layout',
                                    icon: 'pi pi-fw pi-id-card',
                                    routerLink: ['/uikit/formlayout']
                                },
                                {label: 'Input', icon: 'pi pi-fw pi-check-square', routerLink: ['/uikit/input']},
                                {
                                    label: 'Float Label',
                                    icon: 'pi pi-fw pi-bookmark',
                                    routerLink: ['/uikit/floatlabel']
                                },
                                {
                                    label: 'Invalid State',
                                    icon: 'pi pi-fw pi-exclamation-circle',
                                    routerLink: ['/uikit/invalidstate']
                                },
                                {label: 'Button', icon: 'pi pi-fw pi-box', routerLink: ['/uikit/button']},
                                {label: 'Table', icon: 'pi pi-fw pi-table', routerLink: ['/uikit/table']},
                                {label: 'List', icon: 'pi pi-fw pi-list', routerLink: ['/uikit/list']},
                                {label: 'Tree', icon: 'pi pi-fw pi-share-alt', routerLink: ['/uikit/tree']},
                                {label: 'Panel', icon: 'pi pi-fw pi-tablet', routerLink: ['/uikit/panel']},
                                {label: 'Overlay', icon: 'pi pi-fw pi-clone', routerLink: ['/uikit/overlay']},
                                {label: 'Media', icon: 'pi pi-fw pi-image', routerLink: ['/uikit/media']},
                                {
                                    label: 'Menu',
                                    icon: 'pi pi-fw pi-bars',
                                    routerLink: ['/uikit/menu'],
                                    routerLinkActiveOptions: {
                                        paths: 'subset',
                                        queryParams: 'ignored',
                                        matrixParams: 'ignored',
                                        fragment: 'ignored'
                                    }
                                },
                                {label: 'Message', icon: 'pi pi-fw pi-comment', routerLink: ['/uikit/message']},
                                {label: 'File', icon: 'pi pi-fw pi-file', routerLink: ['/uikit/file']},
                                {label: 'Chart', icon: 'pi pi-fw pi-chart-bar', routerLink: ['/uikit/charts']},
                                {label: 'Misc', icon: 'pi pi-fw pi-circle', routerLink: ['/uikit/misc']}
                            ]
                        },
                        {
                            label: 'Prime Blocks',
                            items: [
                                {
                                    label: 'Free Blocks',
                                    icon: 'pi pi-fw pi-eye',
                                    routerLink: ['/blocks'],
                                    badge: 'NEW'
                                },
                                {
                                    label: 'All Blocks',
                                    icon: 'pi pi-fw pi-globe',
                                    url: ['https://www.primefaces.org/primeblocks-ng'],
                                    target: '_blank'
                                },
                            ]
                        },
                        {
                            label: 'Utilities',
                            items: [
                                {label: 'PrimeIcons', icon: 'pi pi-fw pi-prime', routerLink: ['/utilities/icons']},
                                {
                                    label: 'PrimeFlex',
                                    icon: 'pi pi-fw pi-desktop',
                                    url: ['https://www.primefaces.org/primeflex/'],
                                    target: '_blank'
                                },
                            ]
                        },
                        {
                            label: 'Pages',
                            icon: 'pi pi-fw pi-briefcase',
                            items: [
                                {
                                    label: 'Landing',
                                    icon: 'pi pi-fw pi-globe',
                                    routerLink: ['/landing']
                                },
                                {
                                    label: 'Auth',
                                    icon: 'pi pi-fw pi-user',
                                    items: [
                                        {
                                            label: 'Login',
                                            icon: 'pi pi-fw pi-sign-in',
                                            routerLink: ['/auth/login']
                                        },
                                        {
                                            label: 'Error',
                                            icon: 'pi pi-fw pi-times-circle',
                                            routerLink: ['/auth/error']
                                        },
                                        {
                                            label: 'Access Denied',
                                            icon: 'pi pi-fw pi-lock',
                                            routerLink: ['/auth/access']
                                        }
                                    ]
                                },
                                {
                                    label: 'Crud',
                                    icon: 'pi pi-fw pi-pencil',
                                    routerLink: ['/pages/crud']
                                },
                                {
                                    label: 'Timeline',
                                    icon: 'pi pi-fw pi-calendar',
                                    routerLink: ['/pages/timeline']
                                },
                                {
                                    label: 'Not Found',
                                    icon: 'pi pi-fw pi-exclamation-circle',
                                    routerLink: ['/notfound']
                                },
                                {
                                    label: 'Empty',
                                    icon: 'pi pi-fw pi-circle-off',
                                    routerLink: ['/pages/empty']
                                },
                            ]
                        },
                        {
                            label: 'Hierarchy',
                            items: [
                                {
                                    label: 'Submenu 1', icon: 'pi pi-fw pi-bookmark',
                                    items: [
                                        {
                                            label: 'Submenu 1.1', icon: 'pi pi-fw pi-bookmark',
                                            items: [
                                                {label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-bookmark'},
                                                {label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-bookmark'},
                                                {label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-bookmark'},
                                            ]
                                        },
                                        {
                                            label: 'Submenu 1.2', icon: 'pi pi-fw pi-bookmark',
                                            items: [
                                                {label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-bookmark'}
                                            ]
                                        },
                                    ]
                                },
                                {
                                    label: 'Submenu 2', icon: 'pi pi-fw pi-bookmark',
                                    items: [
                                        {
                                            label: 'Submenu 2.1', icon: 'pi pi-fw pi-bookmark',
                                            items: [
                                                {label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-bookmark'},
                                                {label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-bookmark'},
                                            ]
                                        },
                                        {
                                            label: 'Submenu 2.2', icon: 'pi pi-fw pi-bookmark',
                                            items: [
                                                {label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-bookmark'},
                                            ]
                                        },
                                    ]
                                }
                            ]
                        },
                        {
                            label: 'Get Started',
                            items: [
                                {
                                    label: 'Documentation',
                                    icon: 'pi pi-fw pi-question',
                                    routerLink: ['/documentation']
                                },
                                {
                                    label: 'View Source',
                                    icon: 'pi pi-fw pi-search',
                                    url: ['https://github.com/primefaces/sakai-ng'],
                                    target: '_blank'
                                }
                            ]
                        }
                    ]
                }
            );
        }
        this.model = newModel;
    }
    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe())
    }

    async jumpToSection(section: Section) {
        await this.documentService.jumpToSection(section);
    }
}
