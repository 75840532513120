import Quill from "quill";
import {EditorComponent} from "../editor/editor.component";
import {RNGQuillToolbarSplitButton, SplitButtonItem} from "../../api/quill/RNGQuillToolbarSplitButton";
import {SectionMarker, SectionBlot} from "./SectionBlot";
import {Section, TableOfContentsBlot} from "./TableOfContentsBlot";
import {Comment} from "../comment/RNG_Comments";

export class RNGSectionModule {
    private static quill: any;
    private options: any;

    private editorComponent: EditorComponent;
    private splitButton: RNGQuillToolbarSplitButton;
    private splitButtonItems: SplitButtonItem[];

    constructor(quillOb, options) {
        RNGSectionModule.quill = quillOb;
        this.options = options;

        Quill.register(SectionBlot, true);
        Quill.register(TableOfContentsBlot, true);

        this.editorComponent = this.options.editorComponent;
        this.editorComponent.setReferenceToSectionModule(this);
    }

    onAngularInit() {
        //OK - this isn't really Angular at this level - it has to be specifically called byt the editor component to initialize it once it has set everything up
        const projectSchema = this.editorComponent.getProjectSchema();

        let toolbar = RNGSectionModule.quill.getModule('toolbar');
        if (toolbar) {
            this.splitButtonItems = [];
            let selectedItem: SplitButtonItem = null;
            //We can only allow them to split the docuemnt - creating a new one at the current level (if the current level is > the project level) or create on at the current level + 1
            let level = 0;
            for ( const sl of projectSchema.sectionLayout) {
                if (level++ !== 0) { //Top level isn't a split that can happen - or there wouldn't be a container for it. . .

                    const item: SplitButtonItem = {
                        label: sl.sectionName,
                        dropDownLabel: sl.toolbarDropDownLabel,
                        iconHTML: sl.toolbarIconHTML,
                        value: {
                            level: level,
                            sectionName: sl.sectionName,
                            latexCode: sl.latexCode,
                            tocIconHTML: sl.tocIconHTML
                        },
                        enabled: true
                    };
                    this.splitButtonItems.push(item);
                }
            }
            this.splitButton = new RNGQuillToolbarSplitButton('rng-section-splitbutton', this.splitButtonItems[0], this.splitButtonItems, this.options.divideSectionClick, this.addSection);

            this.splitButton.attach(RNGSectionModule.quill);
        } else {
            console.log('Error: WPSQLSectionModule needs toolbar');
        }
    }

    addSection(section: Section) {

        if (!section) {
            return; // cannot work without comment
        }



        //TODO: Split the file at the current cursor and place the defined Section at the top of the new delta.

        console.log("CALLBACK FOR DIVIDE CALLED WITH VALUE:", section);
        //RNGSectionModule.quill.format('section', JSON.stringify(section), 'api');

    }

}
