<p-dialog *ngIf="selectedComment" appendTo="body" header="Add Comment" [modal]="true" [(visible)]="showAddCommentDialog" [closable]="false">
    <comment isNew="true" [user]="platformUser" [comment]="selectedComment()"></comment>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="showAddCommentDialog.set(false);closeComment()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="showAddCommentDialog.set(false);saveNewComment()"></button>
        <!-- If this isn't a new comment, then "resolve" needs to be available. -->
    </ng-template>
</p-dialog>
<p-dialog *ngIf="selectedComment" appendTo="body" [draggable]="true" [resizable]="true"  [header]="showMultiple?'All Comments':'View Comment'" [modal]="!showMultiple" [(visible)]="showComments" (onShow)="markViewed()" [closable]="false">
    <!-- span class="hidden md:block p-text-secondary mb-5">Show All <p-inputSwitch [(ngModel)]="showMultiple" /></span -->
    <comment *ngIf="!showMultiple" [isNew]="false" [user]="platformUser" [baseReply]="newReply" [comment]="selectedComment()"></comment>
    <ng-template pTemplate="footer">
        <button  pButton pRipple label="Close" icon="pi pi-times" class="p-button-text" (click)="showComments.set(false);closeNewReply()"></button>
        <button  pButton pRipple label="Save Reply" icon="pi pi-check" class="p-button-text" (click)="showComments.set(false);saveNewReply()"></button>
        <!-- If this isn't a new comment, then "resolve" needs to be available. -->
    </ng-template>
</p-dialog>
<quill-editor [readOnly]="isReadOnly" (onEditorCreated)="onEditorCreated($event)" [modules]="modules" [placeholder]="'Welcome to Scrivendium. Write your content here!'" [theme]="'snow'" [style]="{ height: '100%' }"/>
