import {inject, Injectable, signal, WritableSignal} from '@angular/core';

import {BehaviorSubject, firstValueFrom, Observable, Subscription} from "rxjs";
import {Project} from "./db/entities/Project";

import {
    PlatformRole
} from "../api/enums";
import BadWordsFilter from "bad-words";
import {v4 as uuid} from "uuid";
import {activateSignalDebugger, ErrorService, LogProvider} from "./error.service";
import {ProgressService} from "./progress.service";
import {GatherDebugData} from "./gather-debug-data";

import {Section, TableOfContents} from "../components/contents-management/TableOfContentsBlot";
import {Delta} from "quill/core";

import {EditorComponent} from "../components/editor/editor.component";
import {StorageMap} from "@ngx-pwa/local-storage";
import {ORM} from "../utils";
import {ProjectSchema} from "../api/project_schemas";

@Injectable({
    providedIn: 'root'
})
export class DocumentService implements GatherDebugData {

    private readonly LOG = LogProvider.getLogger('DocumentService');

    private hydration = undefined;

    selectedProject: WritableSignal<Project> = signal(null); // Filled on editorCreate
    private _selectedProjectObs: BehaviorSubject<Project> = new BehaviorSubject(this.selectedProject());
    public readonly selectedProjectObs: Observable<Project> = this._selectedProjectObs.asObservable();

    tableOfContents: WritableSignal<TableOfContents> = signal(null); // Filled on editorCreate
    private _tableOfContentsObs: BehaviorSubject<TableOfContents> = new BehaviorSubject(this.tableOfContents());
    public readonly tableOfContentsObs: Observable<TableOfContents> = this._tableOfContentsObs.asObservable();

    private tableOfContentsOrderedList: Section[];

    //TODO: We'll need a push mechanism of some sort for multi-user updates - WebSocket or something.
    //TODO:   Also needs mechanism to lock the document to use offline?

    constructor(
        private storage: StorageMap,
        private errorService: ErrorService) {

        errorService.registerServiceForDebug('DocumentService', this);
        activateSignalDebugger<Project>(this.selectedProject, 'DocumentService.selectedProject');
        activateSignalDebugger<TableOfContents>(this.tableOfContents, 'DocumentService.tableOfContents');

    }

    private async checkTOC(): Promise<TableOfContents> {
        // Check if the current version is the latest - this means checking remote cache for multi-user
        const toc = this.tableOfContents();

        //Since we are assuming local, for now - we are done.

        //Let's make sure the level on the toc is legal for what is IN the toc. . .

        if (toc.projectSchema && (toc.projectSchema.projectLevel < toc.topLevelSectionLink.section.level)) {
            //Uh-oh - fix it - this may have happened from an old bug, but the data still needs fixed!
            toc.projectSchema.projectLevel = toc.topLevelSectionLink.section.level;
            await this.saveTableOfContents(toc);
        }

        return toc;
    }

    private async buildTOCOrderedList() {
        const toc = this.tableOfContents();
        this.tableOfContentsOrderedList = [toc.topLevelSectionLink.section];
        this.walkTOCInOrder(toc.topLevelSectionLink, this.tableOfContentsOrderedList);
    }

    private walkTOCInOrder(start: SectionLink, out: Section[]) {
        if (start.childLinks?.length > 0) {
            for (const sl of start.childLinks) {
                out.push(sl.section);
                this.walkTOCInOrder(sl, out);
            }
        }
    }

    async jumpToSection(section: Section) {
        await EditorComponent.jumpToSection(section);
    }

    getDebugInfo() {
        return {

            // writerProjectDescriptorCategories: this.writerProjectDescriptorCategories,
            // betaReaderViewOfProjectDescriptorCategories: this.betaReaderViewOfProjectDescriptorCategories
        };
    }

    async saveTableOfContents(toc: TableOfContents) {
        this.LOG.debug("Saving TOC: ",toc);
        const tocStr = JSON.stringify(toc);
        try {
            const proj = this.selectedProject();
            /*if (proj.currentTableOfContents && proj.currentTableOfContents === toc) {
                //No Need to save
                return;
            }*/
            //Save to local cache
            this.storage.set('TOC_' + toc.topLevelSectionLink.section.uuid, tocStr).subscribe();

            this.tableOfContents.set(toc);
            this._tableOfContentsObs.next(toc);

            const projectRepository = (await ORM.getORMDatasource()).getRepository(Project);
            await projectRepository.update(
                {
                    uuid: proj.uuid,
                },
                {
                    currentTableOfContents: toc
                }
            );
            proj.currentTableOfContents = toc;
            this.selectedProject.set(proj);
        } catch (e) {
            this.errorService.handleError({
                severity: 'error',
                summary: 'Error',
                detail: 'Unable to update Table of Contents. See log for details',
                error: e,
                stack: e.stack
            });
            return;
        }
    }

    async setSelectedProject(selectedProject: Project) {
        this.LOG.debug("Set Selected Project: ", selectedProject);
        this.tableOfContents.set(selectedProject.currentTableOfContents);
        this._tableOfContentsObs.next(selectedProject.currentTableOfContents);
        this.selectedProject.set(selectedProject);
        await this.checkTOC();
    }

    //This returns the SectionLink that contains this child - or null if none
    getParentSectionLinkFromTOC(child: Section, level: number = null): {
        sectionLink: SectionLink,
        childIndex: number
    } {
        const toc = this.tableOfContents().topLevelSectionLink;
        if (child.uuid === toc.section.uuid) {
            return null; //No parent - this is the top
        }
        return this.getParentSectionLink(child, toc, level);
    }

    private getParentSectionLink(child: Section, possibleParent: SectionLink, level: number): {
        sectionLink: SectionLink,
        childIndex: number
    } {

        const idx = possibleParent.childLinks.findIndex(sl => sl.section.uuid === child.uuid);
        if (idx > -1) {
            if (!level || possibleParent.section.level === level) {
                return {sectionLink: possibleParent, childIndex: idx}; //Found it, so this is the parent group; (And it is the right level)
            } else {
                return this.getParentSectionLinkFromTOC(possibleParent.section, level); //Use this found parent as the new child and see if it has the right level as parent
            }
        }
        for (let link of possibleParent.childLinks) {
            const sl = this.getParentSectionLink(child, link, level);
            if (sl) {
                //Found it - let it bubble up
                return sl;
            }
        }
        return null; //Not on this branch
    }

    getSectionLinkFromTOC(child: Section): { sectionLink: SectionLink, childIndex: number } {
        const toc = this.tableOfContents().topLevelSectionLink;
        if (child.uuid === toc.section.uuid) {
            return {sectionLink: toc, childIndex: 0}; //No parent - this is the top - return it
        }
        return this.getSectionLink(child, toc);
    }

    private getSectionLink(child: Section, possibleParent: SectionLink): {
        sectionLink: SectionLink,
        childIndex: number
    } {

        const idx = possibleParent.childLinks.findIndex(sl => sl.section.uuid === child.uuid);
        if (idx > -1) {
            return {sectionLink: possibleParent.childLinks[idx], childIndex: idx}; //Found it, so this is the parent group - return the child;
        }
        for (let link of possibleParent.childLinks) {
            const sl = this.getSectionLink(child, link);
            if (sl) {
                //Found it - let it bubble up
                return sl;
            }
        }
        return null; //Not on this branch
    }

    async getProjectForUUID(uuid: string): Promise<Project> {
        const projectRepository = (await ORM.getORMDatasource()).getRepository(Project);
        return projectRepository.findOneBy({
            uuid: uuid
        });
    }

    async setContent(content: Delta) {
        this.LOG.debug("Saving Content: ",content);
        const contentStr = JSON.stringify(content);
        try {
            const proj = this.selectedProject();
            /*if (proj.currentTableOfContents && proj.currentTableOfContents === toc) {
                //No Need to save
                return;
            }*/
            //Save to local cache
            this.storage.set('Content_' + proj.uuid, contentStr).subscribe();

            this.selectedProject().currentDeltaContent = contentStr;
            this._selectedProjectObs.next(this.selectedProject());

            const projectRepository = (await ORM.getORMDatasource()).getRepository(Project);
            await projectRepository.update(
                {
                    uuid: proj.uuid,
                },
                {
                    currentDeltaContent: contentStr
                }
            );
            proj.currentDeltaContent = contentStr;
            this.selectedProject.set(proj);
        } catch (e) {
            this.errorService.handleError({
                severity: 'error',
                summary: 'Error',
                detail: 'Unable to update Contents. See log for details',
                error: e,
                stack: e.stack
            });
            return;
        }
    }

}

export class SectionLink {
    section: Section;
    childLinks: SectionLink[];
}

export class Caret {
    userID: string;
    sectionUUID: string;
    displayName: string;
    startSelection: number;
    selectionLength: number;
}
