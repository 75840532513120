//Adapted from DynamicQuillTools by T-vk https://github.com/T-vK/DynamicQuillTools/blob/master/DynamicQuillTools.js
// For use with the RNG Scrivendium modified Quill editor


export class RNGQuillToolbarItem {

    protected qlFormatsEl: HTMLSpanElement;  //top level wrapper for this tool
    private canvas: HTMLCanvasElement;

    constructor() {
        this.qlFormatsEl = document.createElement("span");
        this.qlFormatsEl.className = "ql-formats";
    }

    /**
     * Attaches this tool to the given Quill Editor instance.
     *
     * @param {Quill} quill - The Quill Editor instance that this tool should get added to.
     */
    attach(quill) {
        quill.getModule('toolbar').container.appendChild(this.qlFormatsEl);
    }

    /**
     * Detaches this tool from the given Quill Editor instance.
     *
     * @param {Quill} quill - The Quill Editor instance that this tool should get added to.
     */
    protected detach(quill) {
        quill.getModule('toolbar').container.removeChild(this.qlFormatsEl);
    }

    /**
     * Calculate the width of text.
     *
     * @param {string} text - The text of which the length should be calculated.
     * @param {string} [font="500 14px 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"] - The font css that shuold be applied to the text before calculating the width.
     */
    protected getTextWidth(text, font="500 14px 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif") {
        if (!this.canvas) {
            this.canvas = document.createElement("canvas");
        }
        const context = this.canvas.getContext("2d");
        context.font = font;
        const metrics = context.measureText(text);
        return metrics.width;
    }

    /**
     * Add a global css rule to the document.
     *
     * @param {string} cssRule - CSS rules
     */
    protected addCssRule(cssRule) {
        const style = document.createElement("style");
        document.head.appendChild(style);
        style.sheet.insertRule(cssRule, 0);
    }

}
